var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a-form',{staticClass:"form-container",attrs:{"form":_vm.form}},[_c('section',{staticClass:"form-item"},[_c('p',{staticClass:"title"},[_vm._v("培训信息")]),_c('a-row',{attrs:{"type":"flex","gutter":20}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"举办单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'company',
                { rules: [{ required: true, message: '请选择举办单位' }] },
              ]),expression:"[\n                'company',\n                { rules: [{ required: true, message: '请选择举办单位' }] },\n              ]"}],attrs:{"placeholder":"全部"}},_vm._l((_vm.companyMap),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.companyName))])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"培训类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { rules: [{ required: true, message: '请选择培训类型' }] },
              ]),expression:"[\n                'type',\n                { rules: [{ required: true, message: '请选择培训类型' }] },\n              ]"}],attrs:{"placeholder":"全部"}},_vm._l((_vm.trainTypeList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"培训日期 "}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'dateEndTime',
                { rules: [{ required: true, message: '请选择培训日期' }] },
              ]),expression:"[\n                'dateEndTime',\n                { rules: [{ required: true, message: '请选择培训日期' }] },\n              ]"}]})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":20}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"开始时间 "}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'startTime',
                { rules: [{ required: true, message: '请选择开始时间' }] },
              ]),expression:"[\n                'startTime',\n                { rules: [{ required: true, message: '请选择开始时间' }] },\n              ]"}],attrs:{"format":"HH:mm"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"结束时间"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'endTime',
                { rules: [{ required: true, message: '请选择结束时间' }] },
              ]),expression:"[\n                'endTime',\n                { rules: [{ required: true, message: '请选择结束时间' }] },\n              ]"}],attrs:{"format":"HH:mm"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"培训地点"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'addr',
                { rules: [{ required: true, message: '请输入培训地点' },{max:50,message:'最大输入50个字符'}] },
              ]),expression:"[\n                'addr',\n                { rules: [{ required: true, message: '请输入培训地点' },{max:50,message:'最大输入50个字符'}] },\n              ]"}],attrs:{"placeholder":"请输入"}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":20}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"培训标题 "}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'title',
                { rules: [{ required: true, message: '请输入培训标题' },{max:50,message:'最大输入50个字符'}] },
              ]),expression:"[\n                'title',\n                { rules: [{ required: true, message: '请输入培训标题' },{max:50,message:'最大输入50个字符'}] },\n              ]"}],attrs:{"placeholder":"请输入"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":16}},[_c('a-form-item',{attrs:{"label":"培训内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'content',
                { rules: [{ required: false, message: '请输入培训内容' }] },
              ]),expression:"[\n                'content',\n                { rules: [{ required: false, message: '请输入培训内容' }] },\n              ]"}],attrs:{"placeholder":"请输入","rows":"4"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":16}},[_c('a-form-item',{attrs:{"label":"培训照片"}},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-row',{staticClass:"upload-preview-wrap"},_vm._l((_vm.fileLists),function(item,key){return _c('a',{key:key,staticClass:"file-item m-r-10"},[_c('preview-image',{attrs:{"data":item,"size":100}}),_c('a',{staticClass:"close-icon",attrs:{"title":"删除"},on:{"click":function($event){return _vm.deleteFile(item)}}},[_c('a-icon',{attrs:{"type":"close"}})],1)],1)}),0),_c('upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'photo',
                { rules: [{ required: true, message: '请上传图片' }] },
                ]),expression:"[\n                'photo',\n                { rules: [{ required: true, message: '请上传图片' }] },\n                ]"}],staticStyle:{"display":"inline-block","width":"100px"},attrs:{"node":{ type: 3, text: '上传' },"accept":['.png', '.jpg']},on:{"func":_vm.getFile}})],1)],1)],1)],1)],1),_c('section',{staticClass:"form-item"},[_c('p',{staticClass:"title"},[_c('span',{staticClass:"form-title"},[_vm._v("培训人员")]),_c('a',{staticClass:"action-btn",attrs:{"title":"人员设置"},on:{"click":_vm.showSelectPersonModal}},[_c('a-icon',{attrs:{"type":"plus"}})],1)]),_c('section',{staticClass:"list-table-container m-t-18"},[_c('a-table',{attrs:{"size":"small","columns":_vm.columns,"data-source":_vm.personnelList,"pagination":{
            total: _vm.personnelList.length,
            hideOnSinglePage: true,
          }},scopedSlots:_vm._u([{key:"action",fn:function(text, list){return _c('a',{},[_c('a-popconfirm',{attrs:{"title":"确定删除该培训人员?"},on:{"confirm":function($event){return _vm.deletePerson(list)}}},[_vm._v("删除")])],1)}}])})],1)]),_c('a-row',{staticClass:"form-btn-group m-t-18"},[_c('a-col',{attrs:{"span":16}},[_c('a-button',{attrs:{"disabled":_vm.submitLoadding,"loading":_vm.submitLoadding},on:{"click":_vm.submit}},[(!_vm.submitLoadding)?_c('a-icon',{attrs:{"type":"save"}}):_vm._e(),_vm._v("保存")],1),_c('a-button',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("取消")])],1)],1)],1),_c('a-modal',{staticClass:"modal-container",attrs:{"width":"1080px","title":"人员列表","footer":false,"destroyOnClose":true},model:{value:(_vm.selectPersonModal.visible),callback:function ($$v) {_vm.$set(_vm.selectPersonModal, "visible", $$v)},expression:"selectPersonModal.visible"}},[_c('selectPerson',{attrs:{"selected":_vm.personnelList.map((item) => item.id)},on:{"ok":_vm.getSelectPerson,"cancel":_vm.cancelSelect}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }