<template>
  <section>
    <a-row type="flex" class="select-user-page">
      <a-col class="company-list-wrap scroll-wrap">
        <li
          @click="changeCompany(item)"
          :class="item.id == companyId ? 'active' : ''"
          v-for="(item, key) in companyLists"
          :key="key"
        >
          <a><a-icon class="icon" type="home" />{{ item.companyName }}</a>
        </li>
      </a-col>
      <a-col class="user-list-wrap">
        <a-row class="user-list scroll-wrap">
          <a-row type="flex" :gutter="20">
            <a-col class="m-b-10" :span="6" v-for="(item, key) in personnelList" :key="key">
              <a-row class="list-item">
                <a-checkbox
                  v-model="item.checked"
                  class="list-check-box"
                ></a-checkbox>
                <div class="m-b-8">{{ item.personnelName }}</div>
                <span class="decs">{{ item.jobName }}</span>
                
              </a-row>
            </a-col>
          </a-row>
        </a-row>
        <a-row class="select-user-list scroll-wrap">
          <a-row type="flex" :gutter="10">
            <a-col
              class="m-b-10"
              :span="6"
              v-for="(item, key) in selectedPersonnelList"
              :key="key"
            >
              <a-row class="list-item">
                <div class="m-b-8">{{ item.personnelName }}</div>
                <span class="decs">{{ item.jobName }}</span>
                <a class="close-icon" title="删除" @click="item.checked = false"><a-icon type="close"></a-icon></a>
              </a-row>
            </a-col>
          </a-row>
        </a-row>
      </a-col>
      <section class="btn-group form-btn-group">
        <a-button class="m-r-8" @click="save">保存</a-button>
        <a-button @click="cancel">取消</a-button>
      </section>
    </a-row>
  </section>
</template>


<script>
export default {
  data() {
    return {
      companyLists: [],
      companyId: "",
      personnelList: []
    };
  },
  props:['selected'],

  mounted(){
    this.queryPerDropDownBox();
  },
  computed: {
    selectedPersonnelList() {
      let r = [];
      this.companyLists.forEach(item => {
        if (item.personnel && item.personnel.length) {
          item.personnel.forEach(v => {
            if (v.checked) {
              r.push(v);
            }
          });
        }
      });
      return r;
    }
  },
  methods: {
    queryPerDropDownBox() {
      this.$api.queryPerDropDownBox().then(res => {
        if (res.code === 200) {
          let companyLists = res.data || [];
          let selected = this.selected || []
          
          companyLists.forEach(item => {
            if (item.personnel && item.personnel.length) {
              item.personnel.forEach(v => {
                v.personTypeName = v.personnelType == 1 ? '现场管理人员' : v.personnelType == 2 ? '劳务管理人员' : '务工人员'
                v.checked = selected.includes(v.id);
              });
            }
          });
          this.companyLists = companyLists;
          
          this.companyId = this.companyLists.length
            ? this.companyLists[0].id
            : "";
          this.personnelList = this.companyLists.length
            ? this.companyLists[0].personnel
            : [];
        }
      });
    },
    changeCompany(item) {
      this.companyId = item.id;
      this.personnelList = item.personnel || [];
    },
    save() {
      this.$emit("ok", this.selectedPersonnelList);
    },
    cancel() {
      this.$emit("cancel", []);
    }
  }
};
</script>

<style lang="scss" scoped>
.select-user-page {
  position: relative;
  height: 510px;
  .scroll-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .company-list-wrap {
    width: 220px;
    height: 100%;
    border-right: 2px dotted rgba(255, 255, 255, 0.2);
    li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 4px 8px 4px 0;
      .icon {
        margin-right: 6px;
        font-size: 14px;
      }
      a {
        color: rgba(255, 255, 255, 0.8);
      }
      &:hover {
        a {
          color: rgba(76, 132, 211, 1);
        }
      }
    }
    .active {
      a {
        color: rgba(76, 132, 211, 1);
      }
    }
  }
  .user-list-wrap {
    height: 100%;
    width: calc(100% - 220px);
    padding: 0 0 0 10px;
    .user-list {
      height: 55%;
      padding-left: 10px;
      border-bottom: 1px dotted rgba(76, 132, 211, 0.5);
    }
    .select-user-list {
      height: 38%;
      padding: 10px 0 10px 10px;

      .list-item {
        padding: 8px 10px 10px 24px;
        .close-icon{
            position: absolute;
            top:-8px;
            right:-8px;
            width:20px;
            height: 20px;
            font-size: 10px;
            background: rgba(22,22,22,0.8);
            color: #FFFFFF;
            z-index:10;
            border-radius: 100%;
            text-align: center;
            display: none;
        }
        &:hover{
          .close-icon{
            display: block;
          }
        }
      }
    }
    .list-item {
      position: relative;
      border: 1px solid rgba(76, 132, 211, 0.5);
      padding: 8px 10px 10px 44px;
      border-radius: 4px;
      cursor: pointer;
      .decs {
        color: rgba(255, 255, 255, 0.5);
        font-size: 13px;
      }
      .list-check-box {
        position: absolute;
        top: 8px;
        left: 14px;
      }
    }
  }
  .btn-group {
    position: absolute;
    bottom: 0px;
    right: -20px;
    display: inline-block;
  }
}
</style>