<template>
  <section>
    <a-form class="form-container" :form="form">
      <section class="form-item">
        <p class="title">培训信息</p>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="举办单位">
              <a-select
                placeholder="全部"
                v-decorator="[
                  'company',
                  { rules: [{ required: true, message: '请选择举办单位' }] },
                ]"
              >
                <a-select-option
                  v-for="item in companyMap"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.companyName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="培训类型">
              <a-select
                placeholder="全部"
                v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择培训类型' }] },
                ]"
              >
                <a-select-option
                  v-for="item in trainTypeList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="培训日期 ">
              <!-- <a-range-picker v-decorator="['dateEndTime',{rules:[{required:true,message:'请选择培训日期'}]}]"/> -->
              <a-date-picker
                v-decorator="[
                  'dateEndTime',
                  { rules: [{ required: true, message: '请选择培训日期' }] },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="开始时间 ">
              <!-- <a-date-picker
                v-decorator="[
                  'startTime',
                  { rules: [{ required: true, message: '请选择开始时间' }] },
                ]"
              /> -->
              <a-time-picker
                format="HH:mm"
                v-decorator="[
                  'startTime',
                  { rules: [{ required: true, message: '请选择开始时间' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="结束时间">
              <!-- <a-date-picker
                v-decorator="[
                  'endTime',
                  { rules: [{ required: true, message: '请选择结束时间' }] },
                ]"
              /> -->
              <a-time-picker
                format="HH:mm"
                v-decorator="[
                  'endTime',
                  { rules: [{ required: true, message: '请选择结束时间' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="培训地点">
              <a-input
                placeholder="请输入"
                
                v-decorator="[
                  'addr',
                  { rules: [{ required: true, message: '请输入培训地点' },{max:50,message:'最大输入50个字符'}] },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="培训标题 ">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'title',
                  { rules: [{ required: true, message: '请输入培训标题' },{max:50,message:'最大输入50个字符'}] },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="16">
            <a-form-item label="培训内容">
              <a-textarea
                placeholder="请输入"
                rows="4"
                v-decorator="[
                  'content',
                  { rules: [{ required: false, message: '请输入培训内容' }] },
                ]"
              ></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="16">
            <a-form-item label="培训照片">
              <a-row type="flex">
                <a-row class="upload-preview-wrap">
                  <a
                    v-for="(item, key) in fileLists"
                    :key="key"
                    class="file-item m-r-10"
                  >
                    <preview-image :data="item" :size="100" />
                    <a class="close-icon" title="删除" @click="deleteFile(item)"
                      ><a-icon type="close"></a-icon
                    ></a>
                  </a>
                </a-row>
                <upload
                  v-decorator="[
                  'photo',
                  { rules: [{ required: true, message: '请上传图片' }] },
                  ]"
                  style="display: inline-block; width: 100px"
                  @func="getFile"
                  :node="{ type: 3, text: '上传' }"
                  :accept="['.png', '.jpg']"
                />
              </a-row>
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <section class="form-item">
        <p class="title">
          <span class="form-title">培训人员</span>
          <a class="action-btn" title="人员设置" @click="showSelectPersonModal">
            <a-icon type="plus"></a-icon>
          </a>
        </p>
        <section class="list-table-container m-t-18">
          <a-table
            size="small"
            :columns="columns"
            :data-source="personnelList"
            :pagination="{
              total: personnelList.length,
              hideOnSinglePage: true,
            }"
          >
            <a slot="action" slot-scope="text, list">
              <a-popconfirm
                title="确定删除该培训人员?"
                @confirm="deletePerson(list)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>
      <a-row class="form-btn-group m-t-18">
        <a-col :span="16">
          <a-button
            @click="submit"
            :disabled="submitLoadding"
            :loading="submitLoadding"
          >
            <a-icon type="save" v-if="!submitLoadding" />保存</a-button
          >
          <a-button @click="$router.back()">取消</a-button>
        </a-col>
      </a-row>
    </a-form>

    <!-- 人员选择 -->
    <a-modal
      width="1080px"
      class="modal-container"
      v-model="selectPersonModal.visible"
      title="人员列表"
      :footer="false"
      :destroyOnClose="true"
    >
      <selectPerson
        @ok="getSelectPerson"
        @cancel="cancelSelect"
        :selected="personnelList.map((item) => item.id)"
      />
    </a-modal>
  </section>
</template>

<script>
const columns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "所属单位",
    dataIndex: "companyName",
    align: "center",
    key: "companyName",
  },
  {
    title: "姓名",
    dataIndex: "personnelName",
    align: "center",
    key: "personnelName",
  },
  {
    title: "人员类型",
    dataIndex: "personTypeName",
    align: "center",
    key: "personTypeName",
  },
  { title: "职务/工种", dataIndex: "jobName", align: "center", key: "jobName" },
  { title: "操作", scopedSlots: { customRender: "action" }, align: "center" },
];
import selectPerson from "@/components/selectPerson";
import upload from "@/components/upload";
import previewImage from "@/components/previewImage";
import moment from "moment";
export default {
  data() {
    return {
      companyMap: [], //公司下拉列表
      trainTypeList: [], //培训类型列表
      fileLists: [], //文件列表
      //选择人员modal
      selectPersonModal: {
        visible: false,
      },
      columns,
      personnelList: [], //选择人员列表
    };
  },
  components: { upload, selectPerson, previewImage },
  computed: {
    tid() {
      return this.$route.query.id;
    },
    submitLoadding() {
      return this.$store.state.submitLoadding;
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    this.init();
    this.tid && this.querySafeEducationDetails();
  },
  methods: {
    init() {
      this.queryCompanyDropDownBox();
      this.querySafeEducationTypeList();
    },
    //培训类型
    querySafeEducationTypeList() {
      this.$api.querySafeEducationTypeList().then((res) => {
        if (res.code === 200) {
          this.trainTypeList = (res.data && res.data.records) || [];
        }
      });
    },
    //单位下拉
    queryCompanyDropDownBox() {
      this.$api.queryCompanyDropDownBox().then((res) => {
        if (res.code === 200) {
          this.companyMap = res.data || [];
        }
      });
    },
    //选择人员modal
    showSelectPersonModal() {
      this.selectPersonModal.visible = true;
    },
    //选择人员
    getSelectPerson(list) {
      list.forEach((item, ind) => {
        item.key = ind + 1;
      });
      (this.selectPersonModal.visible = false),
        (this.personnelList = [...list]);
    },
    //取消选择
    cancelSelect() {
      this.selectPersonModal.visible = false;
      //this.personnelList = []
    },
    //删除人员
    deletePerson(list) {
      this.personnelList = this.personnelList.filter(
        (item) => item.id !== list.id
      );
      this.personnelList.forEach((item, ind) => {
        item.key = ind + 1;
      });
    },
    querySafeEducationDetails() {
      this.$api.querySafeEducationDetails(this.tid).then((res) => {
        if (res.code === 200) {
          let info = res.data || {};

          let formData = {
            company: info.company,
            title: info.title,
            content: info.content,
            addr: info.addr,
            type: info.type,
            // startTime: info.startTime ? moment(info.startTime) : null,
            // endTime: info.startTime ? moment(info.endTime) : null,
            startTime: info.startTime ? moment(info.startTime, "HH:mm") : null,
            endTime: info.endTime ? moment(info.endTime, "HH:mm") : null,
            // dateEndTime: [
            //   info.dateStartTime ? moment(info.dateStartTime) : null,
            //   info.dateEndTime ? moment(info.dateEndTime) : null,
            // ],
            dateEndTime: info.dateStartTime ? moment(info.dateStartTime) : null,
          };
          this.personnelList = info.persons || [];

          this.personnelList.forEach((item, ind) => {
            item.key = ind + 1;
            item.personnelName = item.personName;
            item.personTypeName = item.personType;
            item.jobName = item.jobTypeName;
            item.id = item.personId;
          });
          // this.fileLists = info.photo ? JSON.parse(info.photo) : []
          this.fileLists = info.photo ? info.photo.split(",") : [];
          formData.photo = this.fileLists.length ? this.fileLists.join(',') : ''
          this.form.setFieldsValue(formData);
        }
      });
    },
    getFile(file) {
      this.fileLists.push(file.codeUrl);
      this.$nextTick(() =>{
        this.form.setFieldsValue({
          photo:this.fileLists.length ? this.fileLists.join(',') : ''
        })
      })
    },
    deleteFile(file) {
      this.fileLists = this.fileLists.filter((item) => item != file);
      this.$nextTick(() =>{
        this.form.setFieldsValue({
          photo:this.fileLists.length ? this.fileLists.join(',') : ''
        })
      })
    },
    //添加和边界提交表单
    submit() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          if (this.personnelList.length === 0) {
            this.$message.warning("请添加培训人员");
            return false;
          }
          //   this.$store.commit("SET_SUBMITLOADDING", true);
          if (this.tid) {
            formData.id = this.tid;
          }
          // formData.dateStartTime = formData.dateEndTime.length ? formData.dateEndTime[0].format('YYYY-MM-DD') : ''
          // formData.dateEndTime = formData.dateEndTime.length ? formData.dateEndTime[1].format('YYYY-MM-DD') : ''
          formData.dateStartTime = formData.dateEndTime.format("YYYY-MM-DD");
          formData.dateEndTime = "";
          //   formData.startTime = formData.startTime.format("YYYY-MM-DD");
          //formData.endTime = formData.endTime.format("YYYY-MM-DD");
          formData.startTime = formData.startTime.format("HH:mm");
          formData.endTime = formData.endTime.format("HH:mm");
          // formData.photo = this.fileLists.length
          //   ? this.fileLists.join(",")
          //   : "";

          formData.persons = this.personnelList.map((item) => {
            return {
              personId: item.id,
            };
          });

          let apiName = this.tid
            ? "updateSafeEducation"
            : "insertSafeEducation";
          this.$api[apiName](formData).then((res) => {
            if (res.code === 200) {
              this.$router.back();
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-title {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}
</style>